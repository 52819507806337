var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.form
    ? _c(
        "div",
        { staticClass: "luckDrawDetail" },
        [
          _c("topOperatingButton", {
            attrs: {
              disabled: _vm.form.auditStatus == 1,
              isAuditBillBtn: false,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm()
              },
              addBill: function ($event) {
                return _vm.submitForm(true)
              },
              getQuit: _vm.getQuit,
            },
          }),
          _c(
            "el-form",
            {
              ref: "myForm",
              attrs: {
                "label-width": "100px",
                size: "mini",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c("div", { staticClass: "main" }, [
                _c("div", { staticClass: "leftImg" }, [
                  _c("div", { staticClass: "pig" }, [
                    _c("div", { staticClass: "turnplate" }, [
                      _c("div", { staticClass: "one" }, [
                        _c("div", { staticClass: "wai-view" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.form
                                  .o2omallMarketingLuckDrawCaseTurntableList[0]
                                  .awardsImageUrl || _vm.defaultPig,
                              alt: "加载失败",
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.form
                                    .o2omallMarketingLuckDrawCaseTurntableList[0]
                                    .awardsName || "谢谢惠顾"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "wai-view" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.form
                                  .o2omallMarketingLuckDrawCaseTurntableList[1]
                                  .awardsImageUrl || _vm.defaultPig,
                              alt: "加载失败",
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.form
                                    .o2omallMarketingLuckDrawCaseTurntableList[1]
                                    .awardsName || "谢谢惠顾"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "wai-view" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.form
                                  .o2omallMarketingLuckDrawCaseTurntableList[2]
                                  .awardsImageUrl || _vm.defaultPig,
                              alt: "加载失败",
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.form
                                    .o2omallMarketingLuckDrawCaseTurntableList[2]
                                    .awardsName || "谢谢惠顾"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "two" }, [
                        _c("div", { staticClass: "wai-view" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.form
                                  .o2omallMarketingLuckDrawCaseTurntableList[7]
                                  .awardsImageUrl || _vm.defaultPig,
                              alt: "加载失败",
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.form
                                    .o2omallMarketingLuckDrawCaseTurntableList[7]
                                    .awardsName || "谢谢惠顾"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "wai-view",
                            staticStyle: {
                              "background-image":
                                "url('https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-22/nyx6gCbSh-NMnfKSl2c1711088376519.png')",
                            },
                          },
                          [
                            _c("p", { staticStyle: { color: "#ffffff" } }, [
                              _vm._v("抽奖"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "wai-view" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.form
                                  .o2omallMarketingLuckDrawCaseTurntableList[3]
                                  .awardsImageUrl || _vm.defaultPig,
                              alt: "加载失败",
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.form
                                    .o2omallMarketingLuckDrawCaseTurntableList[3]
                                    .awardsName || "谢谢惠顾"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "three" }, [
                        _c("div", { staticClass: "wai-view" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.form
                                  .o2omallMarketingLuckDrawCaseTurntableList[6]
                                  .awardsImageUrl || _vm.defaultPig,
                              alt: "加载失败",
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.form
                                    .o2omallMarketingLuckDrawCaseTurntableList[6]
                                    .awardsName || "谢谢惠顾"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "wai-view" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.form
                                  .o2omallMarketingLuckDrawCaseTurntableList[5]
                                  .awardsImageUrl || _vm.defaultPig,
                              alt: "加载失败",
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.form
                                    .o2omallMarketingLuckDrawCaseTurntableList[5]
                                    .awardsName || "谢谢惠顾"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "wai-view" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.form
                                  .o2omallMarketingLuckDrawCaseTurntableList[4]
                                  .awardsImageUrl || _vm.defaultPig,
                              alt: "加载失败",
                            },
                          }),
                          _c("div", { staticClass: "name" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.form
                                    .o2omallMarketingLuckDrawCaseTurntableList[4]
                                    .awardsName || "谢谢惠顾"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c("img", {
                      staticStyle: {
                        position: "absolute",
                        top: "8%",
                        width: "80%",
                        left: "0",
                        right: "0",
                        margin: "0 auto",
                      },
                      attrs: {
                        src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-22/UzQlU7NAPBqsJn2NcXI1711089240263.png",
                        alt: "加载失败",
                      },
                    }),
                    _vm.form.prizeListType === 1
                      ? _c("div", [
                          _c("img", {
                            staticStyle: {
                              position: "absolute",
                              width: "100%",
                              left: "0",
                              top: "63%",
                            },
                            attrs: {
                              src: "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-22/piWFSIvO6cKmLsxDkKn1711089162025.jpg",
                              alt: "加载失败",
                            },
                          }),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "rightInfo" },
                  [
                    _c(
                      "el-scrollbar",
                      [
                        _c(
                          "cardTitleCom",
                          {
                            attrs: {
                              cardTitle: "基础信息",
                              billStatus: _vm.form.billStatus,
                            },
                          },
                          [
                            _c("template", { slot: "cardContent" }, [
                              _c(
                                "div",
                                { staticClass: "basicInfo" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "活动编号",
                                        prop: "billNo",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: "",
                                          placeholder: "自动生成",
                                        },
                                        model: {
                                          value: _vm.form.billNo,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "billNo", $$v)
                                          },
                                          expression: "form.billNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "活动名称",
                                        prop: "billName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          maxlength: "40",
                                        },
                                        model: {
                                          value: _vm.form.billName,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "billName", $$v)
                                          },
                                          expression: "form.billName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "x-f" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "活动日期",
                                            prop: "activityTimes",
                                          },
                                        },
                                        [
                                          _c("el-date-picker", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "datetimerange",
                                              "range-separator": "至",
                                              "start-placeholder": "开始日期",
                                              "end-placeholder": "结束日期",
                                              "default-time": [
                                                "00:00:00",
                                                "23:59:59",
                                              ],
                                              format: "yyyy-MM-dd HH:mm:ss",
                                              "value-format":
                                                "yyyy-MM-dd HH:mm:ss",
                                              "picker-options":
                                                _vm.forbiddenTime,
                                            },
                                            model: {
                                              value: _vm.form.activityTimes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "activityTimes",
                                                  $$v
                                                )
                                              },
                                              expression: "form.activityTimes",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "适用对象",
                                            prop: "vipLevelRangeType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.form.vipLevelRangeType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "vipLevelRangeType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.vipLevelRangeType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("全部会员")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("全部会员+排除")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 2 } },
                                                [_vm._v("指定会员")]
                                              ),
                                            ],
                                            1
                                          ),
                                          [1, 2].includes(
                                            _vm.form.vipLevelRangeType
                                          )
                                            ? _c(
                                                "div",
                                                { staticClass: "vipTable" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "btn x-f marB10",
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleEvent(
                                                                "openVipDialog"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" 选择会员 ")]
                                                      ),
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            disabled:
                                                              _vm.disabled,
                                                            size: "mini",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleEvent(
                                                                "openVipLevelDialog"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " 选择会员级别 "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("EditTable", {
                                                    attrs: {
                                                      disabled: _vm.disabled,
                                                      options: _vm.vipOptions,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "适用时间",
                                            prop: "useTimeRangeType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.form.useTimeRangeType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "useTimeRangeType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.useTimeRangeType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("全部时间")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 2 } },
                                                [_vm._v("指定时间")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.form.useTimeRangeType == 2
                                        ? _c(
                                            "div",
                                            { staticClass: "timeTable" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "btn x-f marB10",
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        disabled: _vm.disabled,
                                                        size: "mini",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleEvent(
                                                            "openTimeDateDialog",
                                                            {
                                                              curTable:
                                                                "timeDateOptions",
                                                            }
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("选择时间 ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("EditTable", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  options: _vm.timeDateOptions,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "参与条件" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "x-x" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .isEveryDrawConsumScore,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "isEveryDrawConsumScore",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.isEveryDrawConsumScore",
                                              },
                                            },
                                            [_vm._v(" 每次抽奖消耗 ")]
                                          ),
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "80px",
                                              margin: "0 4px",
                                            },
                                            attrs: {
                                              disabled:
                                                !_vm.form
                                                  .isEveryDrawConsumScore ||
                                                _vm.disabled,
                                              oninput:
                                                "if (value > 9999999) value = 9999999; value=value.replace(/^0|[^0-9]/g,'')",
                                            },
                                            model: {
                                              value:
                                                _vm.form.everyDrawConsumScore,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "everyDrawConsumScore",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.everyDrawConsumScore",
                                            },
                                          }),
                                          _vm._v(" 积分 "),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "抽奖次数",
                                        prop: "everyOneEveryDayDrawTimes",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "x-x" }, [
                                        _c(
                                          "div",
                                          { staticClass: "x-x" },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .isEveryOneEveryDayDrawTimes,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "isEveryOneEveryDayDrawTimes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.isEveryOneEveryDayDrawTimes",
                                                },
                                              },
                                              [_vm._v(" 每人每天抽 ")]
                                            ),
                                            _c("el-input", {
                                              staticStyle: {
                                                width: "80px",
                                                margin: "0 4px",
                                              },
                                              attrs: {
                                                disabled:
                                                  !_vm.form
                                                    .isEveryOneEveryDayDrawTimes ||
                                                  _vm.disabled,
                                                oninput:
                                                  "if (value > 9999) value = 9999; value=value.replace(/^0|[^0-9]/g,'')",
                                              },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .everyOneEveryDayDrawTimes,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "everyOneEveryDayDrawTimes",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.everyOneEveryDayDrawTimes",
                                              },
                                            }),
                                            _vm._v(" 次 "),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "x-x marL15" },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .isEveryOneTotalDrawTimes,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "isEveryOneTotalDrawTimes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.isEveryOneTotalDrawTimes",
                                                },
                                              },
                                              [_vm._v(" 每人一共抽 ")]
                                            ),
                                            _c("el-input", {
                                              staticStyle: {
                                                width: "80px",
                                                margin: "0 4px",
                                              },
                                              attrs: {
                                                disabled:
                                                  !_vm.form
                                                    .isEveryOneTotalDrawTimes ||
                                                  _vm.disabled,
                                                oninput:
                                                  "if (value > 9999) value = 9999; value=value.replace(/^0|[^0-9]/g,'')",
                                              },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .everyOneTotalDrawTimes,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "everyOneTotalDrawTimes",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.everyOneTotalDrawTimes",
                                              },
                                            }),
                                            _vm._v(" 次 "),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "中奖次数",
                                        prop: "prizeTimesType",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "x-f",
                                          staticStyle: { height: "36px" },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value: _vm.form.prizeTimesType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "prizeTimesType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.prizeTimesType",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("无限制")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: 2 } },
                                                [_vm._v("每人最多抽中")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "80px",
                                              margin: "0 4px",
                                            },
                                            attrs: {
                                              disabled:
                                                _vm.form.prizeTimesType != 2 ||
                                                _vm.disabled,
                                              oninput:
                                                "if (value > 9999) value = 9999; value=value.replace(/^0|[^0-9]/g,'')",
                                            },
                                            model: {
                                              value:
                                                _vm.form.everyOneMaxPrizeTimes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "everyOneMaxPrizeTimes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.everyOneMaxPrizeTimes",
                                            },
                                          }),
                                          _vm._v(" 次 "),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "额外抽奖机会" } },
                                    [
                                      _c("div", { staticClass: "x-x" }, [
                                        _c(
                                          "div",
                                          { staticClass: "x-f" },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .isGetOtherLuckDrawChance,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "isGetOtherLuckDrawChance",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.isGetOtherLuckDrawChance",
                                                },
                                              },
                                              [_vm._v(" 下单获得 ")]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "tag marL10" },
                                              [
                                                _vm._v(
                                                  "(每支付完成1笔订单,可获得1次)"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        [
                                          _vm._v(" 订单最低门槛为 "),
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "70px",
                                              margin: "0 4px",
                                            },
                                            attrs: {
                                              disabled:
                                                !_vm.form
                                                  .isGetOtherLuckDrawChance ||
                                                _vm.disabled,
                                              precision: 2,
                                              min: 0,
                                              oninput:
                                                "if (value > 9999999) value = 9999999; value=value.replace(/^0|[^0-9]/g,'')",
                                            },
                                            model: {
                                              value:
                                                _vm.form
                                                  .otherOrderThresholdMoney,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "otherOrderThresholdMoney",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.otherOrderThresholdMoney",
                                            },
                                          }),
                                          _vm._v("元 "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "75px" },
                                              attrs: {
                                                disabled:
                                                  !_vm.form
                                                    .isGetOtherLuckDrawChance ||
                                                  _vm.disabled,
                                              },
                                              model: {
                                                value: _vm.form.otherEveryType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "otherEveryType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.otherEveryType",
                                              },
                                            },
                                            _vm._l(
                                              [
                                                { label: "每天", value: 1 },
                                                { label: "每人", value: 2 },
                                              ],
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _vm._v(" 最多可获得 "),
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "60px",
                                              margin: "0 4px",
                                            },
                                            attrs: {
                                              disabled:
                                                !_vm.form
                                                  .isGetOtherLuckDrawChance ||
                                                _vm.disabled,
                                              min: 0,
                                              oninput:
                                                "if (value > 9999) value = 9999; value=value.replace(/^0|[^0-9]/g,'')",
                                            },
                                            model: {
                                              value: _vm.form.otherGetMaxTimes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "otherGetMaxTimes",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.otherGetMaxTimes",
                                            },
                                          }),
                                          _vm._v("次 "),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "参与送积分" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "x-x" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .isJoinLuckDrawLargessScore,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "isJoinLuckDrawLargessScore",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.isJoinLuckDrawLargessScore",
                                              },
                                            },
                                            [_vm._v(" 参与抽奖送 ")]
                                          ),
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "80px",
                                              margin: "0 4px",
                                            },
                                            attrs: {
                                              disabled:
                                                !_vm.form
                                                  .isJoinLuckDrawLargessScore ||
                                                _vm.disabled,
                                              oninput:
                                                "if (value > 9999999) value = 9999999; value=value.replace(/^0|[^0-9]/g,'')",
                                            },
                                            model: {
                                              value:
                                                _vm.form
                                                  .joinLuckDrawLargessScore,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "joinLuckDrawLargessScore",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.joinLuckDrawLargessScore",
                                            },
                                          }),
                                          _vm._v(" 积分 "),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "x-x" },
                                        [
                                          _c(
                                            "el-checkbox",
                                            {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value:
                                                  _vm.form.isJustLargessNoPrize,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "isJustLargessNoPrize",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.isJustLargessNoPrize",
                                              },
                                            },
                                            [_vm._v(" 仅送给未中奖用户 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c("comCard", {
                          attrs: { title: "奖品设置" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "中奖名单" } },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value: _vm.form.prizeListType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "prizeListType",
                                                  $$v
                                                )
                                              },
                                              expression: "form.prizeListType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("展示")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 2 } },
                                              [_vm._v("不展示")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "玩法规则" } },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "230px" },
                                          attrs: {
                                            disabled: _vm.disabled,
                                            type: "textarea",
                                            autosize: {
                                              minRows: 2,
                                              maxRows: 4,
                                            },
                                            placeholder: "请输入内容",
                                            maxlength: "500",
                                          },
                                          model: {
                                            value: _vm.form.luckDrawRule,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "luckDrawRule",
                                                $$v
                                              )
                                            },
                                            expression: "form.luckDrawRule",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "prizeSet" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "addPrize x-f" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "addBtn",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addLuckDraw()
                                                  },
                                                },
                                              },
                                              [_vm._v("添加奖品")]
                                            ),
                                            _c("div", { staticClass: "num" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    "(" +
                                                      _vm.form
                                                        .o2omallMarketingLuckDrawCaseAwardsAddReqList
                                                        .length +
                                                      " / 7)"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "el-table",
                                          {
                                            staticClass: "table",
                                            attrs: {
                                              data: _vm.form
                                                .o2omallMarketingLuckDrawCaseAwardsAddReqList,
                                              border: "",
                                            },
                                          },
                                          [
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "奖品名称",
                                                prop: "awardsName",
                                                "min-width": "130",
                                              },
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "奖品类型",
                                                prop: "awardsType",
                                                "min-width": "90",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.awardsTypes[
                                                                scope.row
                                                                  .awardsType
                                                              ]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3009661032
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "奖品图片",
                                                "min-width": "90",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c("img", {
                                                          staticStyle: {
                                                            width: "40px",
                                                            "object-fit":
                                                              "cover",
                                                            height: "40px",
                                                          },
                                                          attrs: {
                                                            src:
                                                              scope.row
                                                                .awardsImageUrl ||
                                                              _vm.defaultPig,
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                927758398
                                              ),
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "发放总量",
                                                prop: "awardsLimitCount",
                                                "min-width": "130",
                                              },
                                            }),
                                            _c("el-table-column", {
                                              attrs: {
                                                label: "操作",
                                                "min-width": "100",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function (scope) {
                                                      return [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm.disabled,
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.updateLuckDraw(
                                                                  scope.$index,
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("编辑")]
                                                        ),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm.disabled,
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleEvent(
                                                                  "del-draw",
                                                                  scope.$index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v(" 删除 ")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2442395423
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1883011412
                          ),
                        }),
                        _c("comCard", {
                          attrs: { title: "未中奖设置" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "noPrizeSet" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "noPrizeSetImg",
                                            attrs: { label: "图片" },
                                          },
                                          [
                                            _c("OssUpload", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                option: { listType: "img" },
                                                size: "1mb",
                                              },
                                              model: {
                                                value: _vm.form.noPrizeImageUrl,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "noPrizeImageUrl",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.noPrizeImageUrl",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "提示语" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                maxlength: 40,
                                              },
                                              model: {
                                                value: _vm.form.noPrizePrompt,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "noPrizePrompt",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.noPrizePrompt",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3737978187
                          ),
                        }),
                        _c("comCard", {
                          attrs: { title: "转盘设置" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "content",
                                fn: function () {
                                  return [
                                    _c("div", { staticClass: "wheelSet" }, [
                                      _c(
                                        "div",
                                        { staticClass: "x-x" },
                                        [
                                          _c(
                                            "el-table",
                                            {
                                              ref: "Table",
                                              staticClass: "table",
                                              attrs: {
                                                data: _vm.form
                                                  .o2omallMarketingLuckDrawCaseTurntableList,
                                                border: "",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "转盘位置",
                                                  type: "index",
                                                  width: "80",
                                                  align: "center",
                                                },
                                              }),
                                              _c(
                                                "el-table-column",
                                                {
                                                  attrs: {
                                                    label: "奖品",
                                                    align: "center",
                                                    prop: "goodsNo",
                                                    width: "280",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-form-item",
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    attrs: {
                                                                      placeholder:
                                                                        "请选择奖品",
                                                                      disabled:
                                                                        _vm.disabled ||
                                                                        (!scope
                                                                          .row
                                                                          .awardsId &&
                                                                          _vm.form.o2omallMarketingLuckDrawCaseTurntableList.filter(
                                                                            function (
                                                                              x
                                                                            ) {
                                                                              return x.awardsId
                                                                            }
                                                                          )
                                                                            .length ===
                                                                            7),
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          e
                                                                        ) {
                                                                          return _vm.setListDraw(
                                                                            e,
                                                                            scope.$index
                                                                          )
                                                                        },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        scope
                                                                          .row
                                                                          .awardsId,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            scope.row,
                                                                            "awardsId",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "scope.row.awardsId",
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.drawList,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "el-option",
                                                                        {
                                                                          key: index,
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                item.label,
                                                                              value:
                                                                                item.value,
                                                                            },
                                                                        }
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1776711021
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "header" },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticStyle: {
                                                            color: "#ff4949",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(" 奖品 "),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                              _c("el-table-column", {
                                                attrs: {
                                                  label: "转盘图片",
                                                  align: "center",
                                                  prop: "goodsNo",
                                                  width: "140",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function (scope) {
                                                        return [
                                                          _c("el-form-item", [
                                                            _c("img", {
                                                              staticStyle: {
                                                                width: "40px",
                                                                "object-fit":
                                                                  "cover",
                                                                height: "40px",
                                                              },
                                                              attrs: {
                                                                src:
                                                                  scope.row
                                                                    .awardsImageUrl ||
                                                                  _vm.defaultPig,
                                                                alt: "",
                                                              },
                                                            }),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  877384515
                                                ),
                                              }),
                                              _c(
                                                "el-table-column",
                                                {
                                                  attrs: {
                                                    label: "转到概率",
                                                    align: "center",
                                                    prop: "goodsNo",
                                                    width: "200",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function (scope) {
                                                          return [
                                                            _c(
                                                              "el-form-item",
                                                              [
                                                                _c("el-input", {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "4px",
                                                                    width:
                                                                      "120px",
                                                                  },
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.disabled,
                                                                    maxlength:
                                                                      _vm.maxPrizeOdds,
                                                                  },
                                                                  on: {
                                                                    focus:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.prizeFB(
                                                                          scope.$index
                                                                        )
                                                                      },
                                                                    blur: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.prizeFB(
                                                                        -1
                                                                      )
                                                                    },
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      scope.row
                                                                        .prizeOdds,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          scope.row,
                                                                          "prizeOdds",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "scope.row.prizeOdds",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    339552306
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "header" },
                                                    [
                                                      _c(
                                                        "i",
                                                        {
                                                          staticStyle: {
                                                            color: "#ff4949",
                                                          },
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _vm._v(" 转到概率(%) "),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "sketchMap" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "one" },
                                                [
                                                  _c("div", [_vm._v("1")]),
                                                  _c("div", [_vm._v("2")]),
                                                  _c("div", [_vm._v("3")]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "two" },
                                                [
                                                  _c("div", [_vm._v("8")]),
                                                  _c("div", [_vm._v("4")]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "three" },
                                                [
                                                  _c("div", [_vm._v("7")]),
                                                  _c("div", [_vm._v("6")]),
                                                  _c("div", [_vm._v("5")]),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "tag" },
                                                [_vm._v("转盘位置示意图")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1096281325
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c("Dialog", {
            attrs: { options: _vm.dialogOptions },
            on: {
              "update:options": function ($event) {
                _vm.dialogOptions = $event
              },
              handleEvent: _vm.handleEvent,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }