<template>
  <!-- 抽奖活动明细 -->
  <div class="luckDrawDetail" v-if="form">
    <!-- 头部定位区域 -->
    <topOperatingButton :disabled="form.auditStatus == 1" :isAuditBillBtn="false" @submitForm="submitForm()"
      @addBill="submitForm(true)" @getQuit="getQuit" id="topOperatingButton">
    </topOperatingButton>
    <el-form label-width="100px" size="mini" :model="form" :rules="rules" ref="myForm">
      <div class="main">
        <!-- 抽奖左边图片 -->
        <div class="leftImg">
          <div class="pig">
            <div class="turnplate">
              <div class="one">
                <div class="wai-view">
                  <img :src="
                      form.o2omallMarketingLuckDrawCaseTurntableList[0].awardsImageUrl ||
                      defaultPig
                    " alt="加载失败" />
                  <div class="name">
                    {{
                      form.o2omallMarketingLuckDrawCaseTurntableList[0].awardsName ||
                      "谢谢惠顾"
                    }}
                  </div>
                </div>
                <div class="wai-view">
                  <img :src="
                      form.o2omallMarketingLuckDrawCaseTurntableList[1].awardsImageUrl ||
                      defaultPig
                    " alt="加载失败" />
                  <div class="name">
                    {{
                      form.o2omallMarketingLuckDrawCaseTurntableList[1].awardsName ||
                      "谢谢惠顾"
                    }}
                  </div>
                </div>
                <div class="wai-view">
                  <img :src="
                      form.o2omallMarketingLuckDrawCaseTurntableList[2].awardsImageUrl ||
                      defaultPig
                    " alt="加载失败" />
                  <div class="name">
                    {{
                      form.o2omallMarketingLuckDrawCaseTurntableList[2].awardsName ||
                      "谢谢惠顾"
                    }}
                  </div>
                </div>
              </div>
              <div class="two">
                <div class="wai-view">
                  <img :src="
                      form.o2omallMarketingLuckDrawCaseTurntableList[7].awardsImageUrl ||
                      defaultPig
                    " alt="加载失败" />
                  <div class="name">
                    {{
                      form.o2omallMarketingLuckDrawCaseTurntableList[7].awardsName ||
                      "谢谢惠顾"
                    }}
                  </div>
                </div>
                <div class="wai-view" style="
                    background-image: url('https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-22/nyx6gCbSh-NMnfKSl2c1711088376519.png')
                      no-repeat;
                  ">
                  <p style="color: #ffffff">抽奖</p>
                </div>
                <div class="wai-view">
                  <img :src="
                      form.o2omallMarketingLuckDrawCaseTurntableList[3].awardsImageUrl ||
                      defaultPig
                    " alt="加载失败" />
                  <div class="name">
                    {{
                      form.o2omallMarketingLuckDrawCaseTurntableList[3].awardsName ||
                      "谢谢惠顾"
                    }}
                  </div>
                </div>
              </div>
              <div class="three">
                <div class="wai-view">
                  <img :src="
                      form.o2omallMarketingLuckDrawCaseTurntableList[6].awardsImageUrl ||
                      defaultPig
                    " alt="加载失败" />
                  <div class="name">
                    {{
                      form.o2omallMarketingLuckDrawCaseTurntableList[6].awardsName ||
                      "谢谢惠顾"
                    }}
                  </div>
                </div>
                <div class="wai-view">
                  <img :src="
                      form.o2omallMarketingLuckDrawCaseTurntableList[5].awardsImageUrl ||
                      defaultPig
                    " alt="加载失败" />
                  <div class="name">
                    {{
                      form.o2omallMarketingLuckDrawCaseTurntableList[5].awardsName ||
                      "谢谢惠顾"
                    }}
                  </div>
                </div>
                <div class="wai-view">
                  <img :src="
                      form.o2omallMarketingLuckDrawCaseTurntableList[4].awardsImageUrl ||
                      defaultPig
                    " alt="加载失败" />
                  <div class="name">
                    {{
                      form.o2omallMarketingLuckDrawCaseTurntableList[4].awardsName ||
                      "谢谢惠顾"
                    }}
                  </div>
                </div>
              </div>
            </div>
            <img style="
                position: absolute;
                top: 8%;
                width: 80%;
                left: 0;
                right: 0;
                margin: 0 auto;
              "
              src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-22/UzQlU7NAPBqsJn2NcXI1711089240263.png"
              alt="加载失败" />
            <div v-if="form.prizeListType === 1">
              <img style="position: absolute; width: 100%; left: 0; top: 63%"
                src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-22/piWFSIvO6cKmLsxDkKn1711089162025.jpg"
                alt="加载失败" />
            </div>
          </div>
        </div>
        <!-- 抽奖右边信息 -->
        <div class="rightInfo">
          <el-scrollbar>
            <!-- 基本信息 -->
            <cardTitleCom cardTitle="基础信息" :billStatus="form.billStatus">
              <template slot="cardContent">
                <div class="basicInfo">
                  <el-form-item label="活动编号" prop="billNo">
                    <el-input v-model="form.billNo" disabled placeholder="自动生成" />
                  </el-form-item>
                  <el-form-item label="活动名称" prop="billName">
                    <el-input :disabled='disabled' v-model="form.billName" maxlength="40" />
                  </el-form-item>
                  <div class="x-f">
                    <el-form-item label="活动日期" prop="activityTimes">
                      <el-date-picker :disabled='disabled' v-model="form.activityTimes" type="datetimerange"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        :default-time="['00:00:00', '23:59:59']" format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss" :picker-options="forbiddenTime">
                      </el-date-picker>
                    </el-form-item>
                  </div>
                  <div>
                    <el-form-item label="适用对象" prop="vipLevelRangeType">
                      <el-radio-group :disabled='disabled' v-model="form.vipLevelRangeType">
                        <el-radio :label="0">全部会员</el-radio>
                        <el-radio :label="1">全部会员+排除</el-radio>
                        <el-radio :label="2">指定会员</el-radio>
                      </el-radio-group>
                      <div class="vipTable" v-if="[1, 2].includes(form.vipLevelRangeType)">
                        <div class="btn x-f marB10">
                          <el-button :disabled='disabled' size="mini" @click="handleEvent('openVipDialog')">
                            选择会员
                          </el-button>
                          <el-button :disabled='disabled' size="mini" @click="handleEvent('openVipLevelDialog')">
                            选择会员级别
                          </el-button>
                        </div>
                        <!-- 会员表格 -->
                        <EditTable :disabled='disabled' :options="vipOptions" />
                        <!-- form.vipLevelItem -->
                      </div>
                    </el-form-item>
                    <el-form-item label="适用时间" prop="useTimeRangeType">
                      <el-radio-group :disabled='disabled' v-model="form.useTimeRangeType">
                        <el-radio :label="0">全部时间</el-radio>
                        <el-radio :label="2">指定时间</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <div class="timeTable" v-if="form.useTimeRangeType == 2">
                      <div class="btn x-f marB10">
                        <el-button :disabled='disabled' size="mini" @click="
                      handleEvent('openTimeDateDialog', {
                        curTable: 'timeDateOptions',
                      })
                    ">选择时间
                        </el-button>
                      </div>
                      <EditTable :disabled='disabled' :options="timeDateOptions" />
                    </div>
                  </div>
                  <el-form-item label="参与条件">
                    <div class="x-x">
                      <el-checkbox :disabled='disabled' v-model="form.isEveryDrawConsumScore">
                        每次抽奖消耗
                      </el-checkbox>
                      <el-input style="width: 80px; margin: 0 4px" :disabled="!form.isEveryDrawConsumScore || disabled"
                        v-model="form.everyDrawConsumScore"
                        oninput="if (value > 9999999) value = 9999999; value=value.replace(/^0|[^0-9]/g,'')" />
                      积分
                    </div>
                  </el-form-item>
                  <el-form-item label="抽奖次数" prop="everyOneEveryDayDrawTimes">
                    <div class="x-x">
                      <div class="x-x">
                        <el-checkbox :disabled='disabled' v-model="form.isEveryOneEveryDayDrawTimes">
                          每人每天抽
                        </el-checkbox>
                        <el-input style="width: 80px; margin: 0 4px"
                          :disabled="!form.isEveryOneEveryDayDrawTimes || disabled"
                          v-model="form.everyOneEveryDayDrawTimes"
                          oninput="if (value > 9999) value = 9999; value=value.replace(/^0|[^0-9]/g,'')" />
                        次
                      </div>
                      <div class="x-x marL15">
                        <el-checkbox :disabled='disabled' v-model="form.isEveryOneTotalDrawTimes">
                          每人一共抽
                        </el-checkbox>
                        <el-input style="width: 80px; margin: 0 4px"
                          :disabled="!form.isEveryOneTotalDrawTimes || disabled" v-model="form.everyOneTotalDrawTimes"
                          oninput="if (value > 9999) value = 9999; value=value.replace(/^0|[^0-9]/g,'')" />
                        次
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item label="中奖次数" prop="prizeTimesType">
                    <div class="x-f" style="height: 36px">
                      <el-radio-group :disabled='disabled' v-model="form.prizeTimesType">
                        <el-radio :label="1">无限制</el-radio>
                        <el-radio :label="2">每人最多抽中</el-radio>
                      </el-radio-group>
                      <el-input style="width: 80px; margin: 0 4px" :disabled="form.prizeTimesType != 2 || disabled"
                        v-model="form.everyOneMaxPrizeTimes"
                        oninput="if (value > 9999) value = 9999; value=value.replace(/^0|[^0-9]/g,'')" />
                      次
                    </div>
                  </el-form-item>
                  <el-form-item label="额外抽奖机会">
                    <div class="x-x">
                      <div class="x-f">
                        <el-checkbox :disabled='disabled' v-model="form.isGetOtherLuckDrawChance">
                          下单获得
                        </el-checkbox>
                        <div class="tag marL10">(每支付完成1笔订单,可获得1次)</div>
                      </div>
                    </div>
                    <div>
                      订单最低门槛为
                      <el-input style="width: 70px; margin: 0 4px"
                        :disabled="!form.isGetOtherLuckDrawChance || disabled" v-model="form.otherOrderThresholdMoney"
                        :precision="2" :min="0"
                        oninput="if (value > 9999999) value = 9999999; value=value.replace(/^0|[^0-9]/g,'')" />元
                    </div>
                    <div>
                      <el-select v-model="form.otherEveryType" :disabled="!form.isGetOtherLuckDrawChance || disabled"
                        style="width: 75px">
                        <el-option v-for="(item,index) in [
                            { label: '每天', value: 1 },
                            { label: '每人', value: 2 },
                          ]" :key="index" :label="item.label" :value="item.value" />
                      </el-select>
                      最多可获得
                      <el-input style="width: 60px; margin: 0 4px"
                        :disabled="!form.isGetOtherLuckDrawChance || disabled" v-model="form.otherGetMaxTimes" :min="0"
                        oninput="if (value > 9999) value = 9999; value=value.replace(/^0|[^0-9]/g,'')" />次
                    </div>
                  </el-form-item>
                  <el-form-item label="参与送积分">
                    <div class="x-x">
                      <el-checkbox :disabled='disabled' v-model="form.isJoinLuckDrawLargessScore">
                        参与抽奖送
                      </el-checkbox>
                      <el-input style="width: 80px; margin: 0 4px"
                        :disabled="!form.isJoinLuckDrawLargessScore || disabled" v-model="form.joinLuckDrawLargessScore"
                        oninput="if (value > 9999999) value = 9999999; value=value.replace(/^0|[^0-9]/g,'')" />
                      积分
                    </div>
                    <div class="x-x">
                      <el-checkbox :disabled='disabled' v-model="form.isJustLargessNoPrize">
                        仅送给未中奖用户
                      </el-checkbox>
                    </div>
                  </el-form-item>
                </div>
              </template>
            </cardTitleCom>
            <!-- 奖品设置 -->
            <comCard :title="`奖品设置`">
              <template #content>
                <el-form-item label="中奖名单">
                  <el-radio-group :disabled='disabled' v-model="form.prizeListType">
                    <el-radio :label="1">展示</el-radio>
                    <el-radio :label="2">不展示</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="玩法规则">
                  <el-input :disabled='disabled' style="width: 230px" type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入内容" v-model="form.luckDrawRule"
                    maxlength="500">
                  </el-input>
                </el-form-item>
                <div class="prizeSet">
                  <div class="addPrize x-f">
                    <div class="addBtn" @click="addLuckDraw()">添加奖品</div>
                    <div class="num">
                      {{
                        `(${form.o2omallMarketingLuckDrawCaseAwardsAddReqList.length} / 7)`
                      }}
                    </div>
                  </div>
                  <el-table class="table" :data="form.o2omallMarketingLuckDrawCaseAwardsAddReqList" border>
                    <el-table-column label="奖品名称" prop="awardsName" min-width="130" />
                    <el-table-column label="奖品类型" prop="awardsType" min-width="90">
                      <template slot-scope="scope">
                        {{ awardsTypes[scope.row.awardsType] }}
                      </template>
                    </el-table-column>
                    <el-table-column label="奖品图片" min-width="90">
                      <template slot-scope="scope">
                        <img style="width: 40px; object-fit: cover; height: 40px"
                          :src="scope.row.awardsImageUrl || defaultPig" alt="" />
                      </template>
                    </el-table-column>
                    <el-table-column label="发放总量" prop="awardsLimitCount" min-width="130" />
                    <el-table-column label="操作" min-width="100">
                      <template slot-scope="scope"><el-button :disabled='disabled' type="text"
                          @click="updateLuckDraw(scope.$index, scope.row)">编辑</el-button>
                        <el-button :disabled='disabled' type="text" @click="handleEvent('del-draw', scope.$index)">
                          删除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
            </comCard>
            <!-- 未中奖设置 -->
            <comCard :title="`未中奖设置`">
              <template #content>
                <div class="noPrizeSet">
                  <!-- <el-form-item label="名称">
                    <el-input v-model="form.noPrizeName"></el-input>
                  </el-form-item> -->
                  <el-form-item label="图片" class="noPrizeSetImg">
                    <OssUpload :disabled='disabled' v-model="form.noPrizeImageUrl" :option="{ listType: 'img' }"
                      size="1mb" />
                  </el-form-item>
                  <el-form-item label="提示语">
                    <el-input :disabled='disabled' v-model="form.noPrizePrompt" :maxlength="40"></el-input>
                  </el-form-item>
                </div>
              </template>
            </comCard>
            <!-- 转盘设置 -->
            <comCard :title="`转盘设置`">
              <template #content>
                <div class="wheelSet">
                  <div class="x-x">
                    <el-table class="table" ref="Table" :data="form.o2omallMarketingLuckDrawCaseTurntableList" border>
                      <el-table-column label="转盘位置" type="index" width="80" align="center" />
                      <el-table-column label="奖品" align="center" prop="goodsNo" width="280">
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          奖品
                        </template>
                        <template slot-scope="scope">
                          <el-form-item>
                            <el-select v-model="scope.row.awardsId" placeholder="请选择奖品" :disabled=" disabled ||
                                (!scope.row.awardsId &&
                                form.o2omallMarketingLuckDrawCaseTurntableList.filter(
                                  (x) => x.awardsId
                                ).length === 7)
                              " @change="(e) => setListDraw(e, scope.$index)">
                              <el-option v-for="(item,index) in drawList" :key="index" :label="item.label"
                                :value="item.value" />
                            </el-select>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column label="转盘图片" align="center" prop="goodsNo" width="140">
                        <template slot-scope="scope">
                          <el-form-item>
                            <img style="width: 40px; object-fit: cover; height: 40px"
                              :src="scope.row.awardsImageUrl || defaultPig" alt="" />
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column label="转到概率" align="center" prop="goodsNo" width="200">
                        <template slot="header">
                          <i style="color: #ff4949">*</i>
                          转到概率(%)
                        </template>
                        <template slot-scope="scope">
                          <el-form-item>
                            <el-input :disabled='disabled' style="margin-right: 4px; width: 120px"
                              v-model="scope.row.prizeOdds" :maxlength="maxPrizeOdds" @focus="prizeFB(scope.$index)"
                              @blur="prizeFB(-1)" />
                          </el-form-item>
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- 转盘位置示意图 -->
                    <div class="sketchMap">
                      <div class="one">
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                      </div>
                      <div class="two">
                        <div>8</div>
                        <div>4</div>
                      </div>
                      <div class="three">
                        <div>7</div>
                        <div>6</div>
                        <div>5</div>
                      </div>
                      <div class="tag">转盘位置示意图</div>
                    </div>
                  </div>
                </div>
              </template>
            </comCard>
          </el-scrollbar>
        </div>
      </div>
    </el-form>
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import { getDateChangeDetail } from "@/utils/public"; //适用时间转换
import Dialog from "@/components/Dialog";
import OssUpload from "@/components/oss-upload/index.vue";
import { getUidNum, fcount, deepCopy } from "@/utils/index.js";
import { getBillNo } from "@/api/codeRule";
import { saveDraw, getDrawDetail, updateDraw } from "@/api/O2OMall/marketing/luckDraw"; //接口api
import EditTable from "@/components/editTable"; //可编辑表格
import { uniqWith } from "lodash"; //去重
import moment from "moment";
// 设置周一为一周的开始
moment.updateLocale("en", { week: { dow: 1 } });
export default {
  name: "LuckDrawDetail",
  components: { Dialog, OssUpload, EditTable, topOperatingButton, cardTitleCom },
  computed: {
    disabled() {
      return this.form.billStatus == 2;
    },
    maxPrizeOdds() {
      const { o2omallMarketingLuckDrawCaseTurntableList: list } = this.form;
      return fcount(
        [
          100,
          this.start
            ? list
              .filter((x, i) => i !== this.prizeIndex)
              .reduce((a, c) => fcount([a, c?.prizeOdds], "+"), 0)
            : 0,
        ],
        "-"
      );
    },
    drawList() {
      return [
        ...(this.form?.o2omallMarketingLuckDrawCaseAwardsAddReqList?.map?.((x, i) => ({
          label: x.awardsName,
          value: x.awardsId,
        })) || []),
        { label: "谢谢惠顾", value: 0 },
      ];
    },
  },
  data() {
    const validator1 = (rule, value, callback) => {
      if (!this.form.isEveryOneEveryDayDrawTimes) {
        callback(new Error("请设置每天抽奖次数"));
      } else if (
        (this.form.isEveryOneTotalDrawTimes) &&
        (Number(this.form.everyOneEveryDayDrawTimes) > Number(this.form.everyOneTotalDrawTimes))
      ) {
        console.log('每天抽取次数不能大于每人一共抽取次数',this.form.everyOneEveryDayDrawTimes,this.form.everyOneTotalDrawTimes,this.form.everyOneEveryDayDrawTimes > this.form.everyOneTotalDrawTimes);
        callback(new Error("每天抽取次数不能大于每人一共抽取次数"));
      } else {
        callback();
      }
    };
    return {
      forbiddenTime: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      timeDateOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        echoList: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "dateTime",
            label: "时间范围",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 200,
            align: "center",
          },
        ],
      },
      start: false,
      vipOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: 200, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "lineType",
            label: "类型",
            minWidth: 120,
            align: "center",
            formatter: (val) => {
              return val == 1 ? "会员" : "会员级别";
            },
          },
          {
            prop: "lineNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineName",
            label: "名称",
            minWidth: 120,
            align: "center",
          },
        ],
      },
      isEdit: false,
      prizeIndex: -1,
      awardsTypes: {
        1: "积分",
        2: "余额",
        3: "优惠券",
      },
      form: {
        vipLevelRangeType: 0,
        useTimeRangeType: 0,
        prizeTimesType: 1,
        o2omallMarketingLuckDrawCaseTurntableList: []
      },
      dialogOptions: {},
      //抽奖转盘默认图片
      defaultPig: require("@/assets/images/luckyDrawIcon.png"),
      rules: {
        billName: [
          { required: true, message: "请输入活动名称", trigger: ["blur", "change"] },
        ],
        activityTimes: [
          { required: true, message: "请输入活动日期", trigger: ["blur", "change"] },
        ],
        vipLevelRangeType: [
          { required: true, message: "请选择适用对象", trigger: ["blur", "change"] },
        ],
        useTimeRangeType: [
          { required: true, message: "请选择适用时间", trigger: ["blur", "change"] },
        ],
        everyOneEveryDayDrawTimes: [
          {
            required: true,
            message: "请设置抽奖次数",
            trigger: ["blur", "change"],
          },
          {
            validator: validator1,
            trigger: ["blur", "change"],
          },
        ],
        prizeTimesType: [
          {
            required: true,
            message: "请设置中奖次数",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  activated() {
    this.init();
  },
  methods: {
    async init(isAdd = false) {
      const o2omallMarketingLuckDrawCaseTurntableList = [
        { sortNo: 1, prizeOdds: 0.0, awardsId: 0 },
        { sortNo: 2, prizeOdds: 0.0, awardsId: 0 },
        { sortNo: 3, prizeOdds: 0.0, awardsId: 0 },
        { sortNo: 4, prizeOdds: 0.0, awardsId: 0 },
        { sortNo: 5, prizeOdds: 0.0, awardsId: 0 },
        { sortNo: 6, prizeOdds: 0.0, awardsId: 0 },
        { sortNo: 7, prizeOdds: 0.0, awardsId: 0 },
        { sortNo: 8, prizeOdds: 0.0, awardsId: 0 },
      ];
      if (!isAdd && this.$route.query?.billId) {
        this.isEdit = true;
        const res = await getDrawDetail(this.$route.query);
        this.form = {
          ...res?.data,
          activityTimes: [res?.data.begTime, res?.data.endTime],
          o2omallMarketingLuckDrawCaseAwardsAddReqList:
            res?.data?.o2omallMarketingLuckDrawCaseAwardsRespList || [],
          o2omallMarketingLuckDrawCaseTurntableList: o2omallMarketingLuckDrawCaseTurntableList.map(
            (x) => {
              let drawItem = {};
              const item = res?.data?.o2omallMarketingLuckDrawCaseTurntableList?.find?.(
                (y) => y.sortNo === x.sortNo
              );
              if (item) {
                drawItem = res?.data?.o2omallMarketingLuckDrawCaseAwardsRespList.find(
                  (y) => y.awardsId === item.awardsId
                );
              }
              return deepCopy({ ...x, ...item, ...drawItem });
            }
          ),
        };
        if (this.form.useTimeRangeType == 2) {
          this.timeDateOptions.list = await getDateChangeDetail(
            this.form.specialDateItems
          );
        }
        this.vipOptions.list = res.data.luckDrawCaseVipLevelList;
      } else {
        this.isEdit = false;
        const res = await getBillNo(180204);
        this.form = {
          prizeTimesType: 1,
          specialDateItems: [],
          vipLevelRangeType: 0,
          useTimeRangeType: 0,
          billNo: res,
          activityTimes: "",
          otherEveryType: 1,
          prizeListType: 1,
          isEveryDrawConsumScore: false,
          isEveryOneTotalDrawTimes: false,
          isGetOtherLuckDrawChance: false,
          isJoinLuckDrawLargessScore: false,
          isJustLargessNoPrize: false,
          everyOneMaxPrizeTimes: 0,
          noPrizeImageUrl:
            "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-18/xBp5cRoLswKhnE2hcXB1710747985754.png",
          noPrizePrompt: "很遗憾，你没有中奖!",
          o2omallMarketingLuckDrawCaseAwardsAddReqList: [],
          o2omallMarketingLuckDrawCaseTurntableList,
        };
      }
      await this.$nextTick();
      try {
        this.$refs.myForm.clearValidate();
      } catch (error) { }
      this.start = true;
    },
    prizeFB(e) {
      this.prizeIndex = e;
    },
    async handleEvent(key, row) {
      switch (key) {
        case "add-draw":
          this.form.o2omallMarketingLuckDrawCaseAwardsAddReqList = [
            ...this.form.o2omallMarketingLuckDrawCaseAwardsAddReqList,
            { ...row.formData, awardsId: Number(getUidNum(11, true)) },
          ];
          break;
        case "update-draw":
          this.$set(
            this.form.o2omallMarketingLuckDrawCaseAwardsAddReqList,
            row.formData.orginIndex,
            row.formData
          );
          this.updateListDraw();
          break;
        case "del-draw":
          this.form.o2omallMarketingLuckDrawCaseAwardsAddReqList.splice(row, 1);
          this.updateListDraw();
          break;
        case "openVipDialog":
          this.dialogOptions = {
            curType: "vipId",
            title: "选择会员",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vip" }),
          };
          break;
        case "openVipLevelDialog":
          this.dialogOptions = {
            curType: "vipLevelId",
            title: "选择会员级别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vipLevel" }),
          };
          break;
        case "dialogChange":
          try {
            let list = this.dialogOptions.formData.table?.check;
            if (this.dialogOptions.curType === "vipLevelId") {
              list = list.flatMap((item) => ({
                ...item,
                lineType: 2,
                lineId: item.vipLevelId,
                lineName: item.vipLevelName,
                lineNo: item.vipLevelNo,
              }));
            } else if (this.dialogOptions.curType === 'timeDate') {
              let list1 = await getDateChangeDetail(this.dialogOptions.formData.DateTimeList);
              this.timeDateOptions.list = list1;
              this.form.specialDateItems = list1;
              return
            } else {
              list = list.flatMap((item) => ({
                ...item,
                lineType: 1,
                lineId: item.vipId,
                lineName: item.vipName,
                lineNo: item.vipNo,
              }));
            }
            list = [...list, ...(this.vipOptions?.list || [])];
            list = uniqWith(list, (x, y) => x.lineId == y.lineId);
            this.vipOptions.list = list;
          } catch (error) {
            console.log(":???????????", error);
          }
          break;
        case "openTimeDateDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "timeDate",
            title: "选择时间日期",
            width: 600,
            show: true,
            type: "TimeDateSelect",
            formData: {
              DateTimeList: this.form.specialDateItems, // 日期
            },
          };
          //适用时间回显
          break;
        default:
          break;
      }
    },
    updateListDraw() {
      this.form.o2omallMarketingLuckDrawCaseTurntableList = this.form.o2omallMarketingLuckDrawCaseTurntableList.map(
        (x) => ({
          ...x,
          ...(this.form.o2omallMarketingLuckDrawCaseAwardsAddReqList.find(
            (y) => x.awardsId === y.awardsId
          ) || { awardsImageUrl: undefined, awardsId: 0, awardsName: "" }),
        })
      );
    },
    addLuckDraw() {
      if (this.disabled) return;
      if (this.form.o2omallMarketingLuckDrawCaseAwardsAddReqList.length === 7)
        return this.$message.error("最多只能添加 7 个奖品！");
      this.dialogOptions = {
        width: 560,
        type: "O2OLuckDraw",
        show: true,
        title: "奖品设置",
        click: "add-draw",
        formData: {
          awardsType: 1,
          awardsLimitCount: undefined,
          o2omallMarketingLuckDrawCaseAwardsCoupon: null,
        },
      };
    },
    updateLuckDraw(i, row) {
      this.dialogOptions = {
        width: 560,
        type: "O2OLuckDraw",
        show: true,
        title: "奖品设置",
        click: "update-draw",
        formData: { ...row, orginIndex: i },
      };
    },
    setListDraw(awardsId, i) {
      if (awardsId) {
        const item = this.form.o2omallMarketingLuckDrawCaseAwardsAddReqList.find(
          (x) => x.awardsId === awardsId
        );
        this.$set(this.form.o2omallMarketingLuckDrawCaseTurntableList, i, {
          ...this.form.o2omallMarketingLuckDrawCaseTurntableList[i],
          ...item,
        });
      } else {
        this.$set(this.form.o2omallMarketingLuckDrawCaseTurntableList, i, {
          ...this.form.o2omallMarketingLuckDrawCaseTurntableList[i],
          awardsImageUrl: undefined,
          awardsId: 0,
          awardsName: "",
        });
      }
      this.$forceUpdate();
    },
    async submitForm(saveAdd) {
      if (saveAdd) {
        try {
          await this.$confirm(
            saveAdd ? "是否保存并新增该抽奖活动吗?" : "是否保存该活动!",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
            }
          );
        } catch (error) {
          return;
        }
      }
      if (this.form.billStatus === 2) return this.$message.error("已审核活动不能修改");
      await this.$refs.myForm.validate();
      if (!this.form?.o2omallMarketingLuckDrawCaseAwardsAddReqList?.length) {
        return this.$message.error("请添加奖品");
      }
      const keys = [
        { key: "everyDrawConsumScore", inKey: "isEveryDrawConsumScore" },
        { key: "everyOneEveryDayDrawTimes", inKey: "isEveryOneEveryDayDrawTimes" },
        { key: "everyOneTotalDrawTimes", inKey: "isEveryOneTotalDrawTimes" },
        { key: "otherOrderThresholdMoney", inKey: "isGetOtherLuckDrawChance" },
        { key: "otherGetMaxTimes", inKey: "isGetOtherLuckDrawChance" },
        { key: "joinLuckDrawLargessScore", inKey: "isJoinLuckDrawLargessScore" },
      ];
      keys.forEach(({ key, inKey } = {}) => {
        if (inKey ? this.form[inKey] && !this.form[key] : !this.form[key]) {
          this.form[key] = 0;
        }
      });
      this.form = {
        ...this.form,
        billDate: moment().format("YYYY-MM-DD hh:mm:ss"),
        begTime: this.form.activityTimes[0],
        endTime: this.form.activityTimes[1],
        luckDrawCaseVipLevelList: [1, 2].includes(this.form.vipLevelRangeType)
          ? this.vipOptions.list.map((x) => ({
            ...x,
            isExcl: this.form.vipLevelRangeType === 1,
          }))
          : undefined,
        o2omallMarketingLuckDrawCaseTurntableAddReqList: this.form
          .o2omallMarketingLuckDrawCaseTurntableList,
        o2omallMarketingLuckDrawCaseAwardsAddReqList: this.form.o2omallMarketingLuckDrawCaseAwardsAddReqList.map(
          (x) => ({
            ...x,
            sortNos: this.form.o2omallMarketingLuckDrawCaseTurntableList
              .filter((y) => x.awardsId === y.awardsId)
              .map((y) => y.sortNo),
          })
        ),
      };
      try {
        await (this.isEdit ? updateDraw : saveDraw)(this.form);
        this.$message.success(this.isEdit ? "修改成功" : "保存成功");
        if (saveAdd) {
          this.init(true);
        } else {
          this.getQuit()
        }
      } catch (error) { }
    },
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/O2OMall/marketing/luckDraw" });
    }
  },
};
</script>

<style lang="scss" scoped>
.luckDrawDetail {
  background-color: #eaeaea;
  .main {
    padding-top: 35px;
    display: flex;
    .leftImg {
      // width: 420px;
      flex: 1;
      background-color: #fff;
      margin: 0 10px;
      margin-right: 0;
      text-align: center;
      //抽奖图片
      .pig {
        display: flex;
        margin-left: 20px;
        width: 375px;
        height: 810px;
        background: url("https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-22/GF-3xXubv2nvb9Kr1gJ1711095930792.png")
          no-repeat;
        position: relative;
        background-size: 100%;
        //转盘盒子
        .turnplate {
          position: absolute;
          left: 61px;
          top: 221px;
          width: 250px;
          height: 250px;

          // background-color: #fff;
          .one,
          .three {
            display: flex;
            justify-content: space-around;
            .wai-view {
              width: 65px;
              height: 70px;
              margin-top: 6px;
              background-size: 100%;
              background-image: url("https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-22/VO45ADqikjE57UsS1Jp1711087852492.png");
              img {
                width: 50px;
                height: 41px;
              }
              .name {
                height: 20px;
                padding-top: 3px;
                font-size: 12px;
              }
            }
          }
          .two {
            display: flex;
            justify-content: space-between;
            margin: 2px 0;
            padding: 0 5px;
            .wai-view {
              width: 65px;
              height: 70px;
              margin-top: 6px;
              background-size: 100%;
              background-image: url("https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-03-22/VO45ADqikjE57UsS1Jp1711087852492.png");
              img {
                width: 50px;
                height: 41px;
              }
              .name {
                padding-top: 3px;
                height: 20px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .rightInfo {
      // width: 100%;
      flex: 3;
      .el-scrollbar {
        overflow: hidden;
        height: 800px;
        //上传图片样式
        .avatar-uploader {
          width: 48px;
          height: 48px;
          margin-left: 15px;
          position: relative;
        }
        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
          border-color: #409eff;
        }
        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 48px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          border: 1px dashed #d9d9d9;
        }
        .avatar {
          width: 48px;
          height: 48px;
          display: block;
        }
        .updateImg {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 999;
          font-size: 12px;
          line-height: 20px;
          background-color: rgba(0, 0, 0, 0.2);
          color: #fff;
        }
        //基本资料区域
        .basicInfo {
          padding: 0 10px;
          .el-input {
            width: 410px;
          }
          ::v-deep .el-date-editor--datetimerange.el-input__inner {
            width: 410px;
          }
          //会员生日下拉框宽度
          .selectVipBD {
            margin-left: 5px;
            width: 140px;
          }
          //输入框宽度
          .Input {
            width: 100px;
            margin-left: 5px;
          }
        }
        //每一行的间距
        .el-form-item {
          margin-bottom: 20px;
        }
        //奖品设置表格区域
        .prizeSet {
          padding: 0 10px 10px 10px;
          margin-left: 13px;
          //新增按钮
          .addPrize {
            font-size: 16px;

            .addBtn {
              color: #409eff;
              cursor: pointer;
            }
            .num {
              color: #a4a5a6;
              margin: 0 5px;
            }
          }
          //使表格校验时显示，校验通过时隐藏
          ::v-deep .el-form-item {
            padding: 0;
            margin: 0;
          }
          ::v-deep .el-form-item__error {
            position: static;
          }
          ::v-deep .el-form-item__content {
            margin-left: 0 !important;
          }
        }
        //未中奖设置区域
        .noPrizeSet {
          .el-input {
            width: 200px;
          }
        }
      }
      //转盘设置区域
      .wheelSet {
        padding: 0 10px;
        //使表格校验时显示，校验通过时隐藏
        ::v-deep .el-form-item {
          padding: 0;
          margin: 0;
        }
        ::v-deep .el-form-item__error {
          position: static;
        }
        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }
        .el-select {
          width: 100%;
        }
        //转盘位置示意图区域
        .sketchMap {
          width: 220px;
          height: 260px;
          margin-left: 30px;
          margin-right: 100px;
          .one,
          .three {
            display: flex;
            justify-content: space-around;
            div {
              width: 70px;
              height: 70px;
              background-color: #f2f3f5;
              text-align: center;
              line-height: 70px;
              color: #c4c5c7;
              font-weight: 700;
              font-size: 30px;
            }
          }
          .two {
            display: flex;
            justify-content: space-between;
            margin: 5px 2px;
            div {
              width: 70px;
              height: 70px;
              background-color: #f2f3f5;
              text-align: center;
              line-height: 70px;
              color: #c4c5c7;
              font-weight: 700;
              font-size: 30px;
            }
          }
          .tag {
            text-align: center;
            line-height: 40px;
            color: #9c9d9f;
          }
        }
      }
      //提示的文字
      .tag {
        margin-bottom: 0;
        color: #a4a5a6;
        line-height: 25px;
      }
    }
  }
}
</style>
